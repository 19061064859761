import { ClientSideAccessiblePublicEnvValues } from "@/common/domain/constants/env/ClientSideAccessiblePublicEnvValues";
import { NoValue } from "@/common/domain/entities/typing/NoValue";
import { DeepReadonly } from "ts-essentials";

const _NetworkEndpoints = {
    bookingService:
        ClientSideAccessiblePublicEnvValues.wezooBookingServiceUrl ||
        `${ClientSideAccessiblePublicEnvValues.wezooApiUrl}/booking`,
    paymentService:
        ClientSideAccessiblePublicEnvValues.wezooPaymentServiceUrl ||
        `${ClientSideAccessiblePublicEnvValues.wezooApiUrl}/payment`,
    locationServiceV1:
        ClientSideAccessiblePublicEnvValues.wezooLocationServiceUrl ||
        `${ClientSideAccessiblePublicEnvValues.wezooApiUrl}/location`,
    locationServiceV2:
        ClientSideAccessiblePublicEnvValues.wezooLocationServiceUrlV2 ||
        `${ClientSideAccessiblePublicEnvValues.wezooApiUrl}/location/v2`,
    mediaService:
        ClientSideAccessiblePublicEnvValues.wezooMediaServiceUrl ||
        `${ClientSideAccessiblePublicEnvValues.wezooApiUrl}/media`,

    workspaceGroups: () =>
        `${_NetworkEndpoints.locationServiceV1}/workspace-group`,
    workspaceGroupsV2: () =>
        `${_NetworkEndpoints.locationServiceV1}/v2/workspace-group`,
    workspaceGroupsV3: () =>
        `${_NetworkEndpoints.locationServiceV1}/v3/workspace-group`,
    workspaceGroup: (id: string | NoValue) =>
        `${_NetworkEndpoints.locationServiceV1}/location/workspace-group/${id ?? ""}`,
    hostLocationsWithWorkspaceGroupsAndMetadata: () =>
        `${_NetworkEndpoints.locationServiceV2}/location`,
    location: (idOrUrlSlug: string) =>
        `${_NetworkEndpoints.hostLocationsWithWorkspaceGroupsAndMetadata()}/${idOrUrlSlug}`,
    summariseBooking: () =>
        `${NetworkEndpoints.bookingService}/booking/summarize`,
    bookingAccountless: () =>
        `${NetworkEndpoints.bookingService}/booking/accountless`,
    cancelBooking: (orderId: string, accessToken: string) =>
        `${NetworkEndpoints.bookingService}/booking/${orderId}/${accessToken}`,
    availability: (workspaceGroupId: string, date: string) =>
        `${NetworkEndpoints.bookingService}/availability/${workspaceGroupId}/${date}`,
    paymentMethods: () => `${NetworkEndpoints.paymentService}/payment-methods`,
    order: (orderId: string) =>
        `${NetworkEndpoints.bookingService}/booking/${orderId}`,
    confirmationPage: () => window.location.origin + "/order/",
    rawImageUrl: (id: string) =>
        `${NetworkEndpoints.mediaService}/download-image/${id}`,
    imageUrl: (
        id: string,
        width: number = 800,
        height: number = 800,
        quality: number = 75
    ) =>
        `${NetworkEndpoints.mediaService}/adjust-image/${id}?width=${width}&height=${height}&format=webp&quality=${quality}`,
    geocodeUrl: (address: string) =>
        `https://api.mapbox.com/search/geocode/v6/forward?q=${address}&access_token=${ClientSideAccessiblePublicEnvValues.wezooMapboxAccessToken}&country=NL,DE,BE,FR,GB,PT,ES`,
    searchOptions: () => `${NetworkEndpoints.locationServiceV1}/search-options`,
};

// Make network endpoints readonly
export const NetworkEndpoints: DeepReadonly<typeof _NetworkEndpoints> =
    _NetworkEndpoints;

import { NoValue } from "@/common/domain/entities/typing/NoValue";
import { WezooError } from "@/common/domain/errors/_base/WezooError";
import { locallyFilterLocations } from "@/features/filtering/application/locallyFilterLocations";
import { useFacilitiesFilter } from "@/features/filtering/filters/facility-filter/useFacilitiesFilter";
import { TSimplifiedHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/SimplifiedHostLocationWithWorkspaceGroupsSchema";
import { AvailableFilters } from "@/features/WezooResourceData";
import { useSearchParams } from "next/navigation";
import hash from "object-hash";
import { useEffect, useState } from "react";
import { useAsync } from "react-async-hook";

interface UseFilteredHostLocationsProps {
    locations: TSimplifiedHostLocationWithWorkspaceGroupsSchema[] | NoValue;
}

export const useFilteredHostLocations = ({
    locations,
}: UseFilteredHostLocationsProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [filteredLocations, setFilteredLocations] = useState<
        TSimplifiedHostLocationWithWorkspaceGroupsSchema[] | null
    >(null);
    const [error, setError] = useState<WezooError | null>(null);

    const queryParamsObject = useSearchParams();
    const queryParams = Object.fromEntries(queryParamsObject.entries());
    const queryParamsHash = hash(queryParams);
    // const hostLocationsHash = hash(locations?.map((l) => l.location.id) || []);
    const { facilitiesStateIsReady } = useFacilitiesFilter();

    const result = useAsync(async () => {
        if (!locations || !facilitiesStateIsReady) {
            setIsLoading(false);
            return {
                filteredLocations: null,
                error: null,
            };
        }
        setIsLoading(true);

        return await locallyFilterLocations(
            locations,
            AvailableFilters,
            queryParams
        );
    }, [facilitiesStateIsReady, locations, queryParamsHash]);

    useEffect(() => {
        setIsLoading(false);

        setError(result.result?.error || null);
        if (result.result?.filteredLocations) {
            setFilteredLocations(result.result.filteredLocations);
        }
    }, [result.result?.filteredLocations, result.result?.error]);

    return {
        filteredLocations,
        error,
        isLoading,
    };
};

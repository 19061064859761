import {
    dateTimeFilterQueryEndKey,
    dateTimeFilterQueryStartKey,
} from "@/features/filtering/filters/date-time-filter/dateTimeFilterQueryKeys";
import moment from "moment/moment";
import {useSearchParams} from "next/navigation";

export const useSelectedDuration = () => {
    const queryParams = useSearchParams();
    const start = queryParams.get(dateTimeFilterQueryStartKey);
    const end = queryParams.get(dateTimeFilterQueryEndKey);
    const hours = start && end ? moment(end).diff(moment(start), "hours") : 1;

    return {
        start,
        end,
        hours,
    };
}
"use client";

import DiscoverMapListLocationCard from "@/app/(main)/discover/[[...params]]/(components)/(cards)/DiscoverMapListLocationCard";
import {
    discoverMapListCardsWrapperStyle,
    discoverMapListEmptyStyle,
    discoverMapListHeaderContentStyle,
    discoverMapListHeaderStyle,
} from "@/app/(main)/discover/[[...params]]/(components)/(map)/DiscoverMapList.css";
import {
    discoverMapListSplitViewMobileMaxWidthInPx,
    discoverMapListSplitViewSidebarWidthInPx,
} from "@/app/(main)/discover/[[...params]]/(components)/discover-map-constants";
import { useDiscoverMapContext } from "@/app/(main)/discover/[[...params]]/(components)/DiscoverMapProvider";
import { hasValue } from "@/common/utilities/hasValue";
import { IconButton } from "@/component-library/components/buttons/icon-button/IconButton";
import SmallLocationCardAlternative1Skeleton from "@/component-library/components/cards/location-cards/small-location-card-alternative-1/SmallLocationCardAlternative1Skeleton";
import Hidden from "@/component-library/components/layout/hidden/Hidden";
import { useSplitViewStore } from "@/component-library/components/layout/split-view/application/SplitViewStoreContext";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { SortingSelect } from "@/component-library/components/sorting-select/SortingSelect";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { theme } from "@/component-library/themes/theme.css";
import { useFilteredHostLocations } from "@/features/filtering/application/useFilteredHostLocations";
import { useSelectedDuration } from "@/features/filtering/application/useSelectedDuration";
import { useSortedHostLocations } from "@/features/filtering/application/useSortedHostLocations";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import React, { useMemo } from "react";
import LazyLoad from "react-lazy-load";
import { useMediaQuery } from "usehooks-ts";

interface DiscoverMapListProps {}

const MAX_NUMBER_OF_LOCATIONS_TO_DISPLAY = 20;

const DiscoverMapList: React.FC<DiscoverMapListProps> = () => {
    const setSidebarExpanded = useSplitViewStore(
        (state) => state.setSidebarExpanded
    );

    const isMobile = useMediaQuery(
        `screen and (max-width: ${discoverMapListSplitViewMobileMaxWidthInPx}px)`,
        { initializeWithValue: true }
    );

    const {
        hostLocations: unfilteredLocations,
        hostLocationError: unfilteredLocationsError,
        isLoading,
    } = useDiscoverMapContext();

    const { filteredLocations } = useFilteredHostLocations({
        locations: unfilteredLocations,
    });
    const { hours } = useSelectedDuration();

    const sortedLocations = useSortedHostLocations({
        locations: filteredLocations,
        filters: {
            hours,
        },
    });

    const hasLocationsVisibleOnTheMap = useMemo(() => {
        return hasValue(sortedLocations) && sortedLocations.length > 0;
    }, [sortedLocations]);

    if (unfilteredLocationsError) {
        throw unfilteredLocationsError;
    }

    return (
        <Flex
            direction="column"
            alignItems="stretch"
            gap={0}
            style={assignInlineVars({
                minWidth: `${discoverMapListSplitViewSidebarWidthInPx}px`,
                width: `${discoverMapListSplitViewSidebarWidthInPx}px`,
                height: "100%",
                minHeight: "100%",
                borderRight: `${Magnitudes.borderWidthInPx.m}px solid ${theme.colors.surface.outline}`,
                paddingBottom: `${Magnitudes.spacingInRem.xl}rem`,
                overflowY: "scroll",
            })}
        >
            <Flex
                direction="row"
                gap={Magnitudes.spacingInRem.xs}
                className={discoverMapListHeaderStyle}
            >
                <Hidden isShowing={isMobile}>
                    <IconButton
                        variant="iconOnly"
                        label={"Hide list view"}
                        onClick={() => setSidebarExpanded(false)}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </IconButton>
                </Hidden>
                <div className={discoverMapListHeaderContentStyle}>
                    <div
                        style={assignInlineVars({
                            minHeight: `${Magnitudes.spacingInRem.xxxl}rem`,
                        })}
                    />
                    <div
                        style={assignInlineVars({
                            width: "100%",
                        })}
                    >
                        <SortingSelect />
                    </div>

                    {/*<h2 className={discoverMapListHeaderHeadingStyle}>*/}
                    {/*    Locations in view*/}
                    {/*    <LoadingIndicator hidden={!isLoading} />*/}
                    {/*</h2>*/}
                    {/*<Hidden*/}
                    {/*    isShowing={!isLoading && !hasLocationsVisibleOnTheMap}*/}
                    {/*>*/}
                    {/*    <div>No locations found within the map view.</div>*/}
                    {/*</Hidden>*/}
                    {/*<Hidden isShowing={hasLocationsVisibleOnTheMap}>*/}
                    {/*    <div>*/}
                    {/*        {searchResultsInfo.numberOfLocations} locations with{" "}*/}
                    {/*        {searchResultsInfo.numberOfRooms +*/}
                    {/*            searchResultsInfo.numberOfHotDesks}{" "}*/}
                    {/*        matching workspaces.*/}
                    {/*    </div>*/}
                    {/*</Hidden>*/}
                </div>
            </Flex>
            <Flex
                direction="column"
                gap={Magnitudes.spacingInRem.xs}
                alignItems="stretch"
            >
                <Hidden isShowing={!!isLoading && !hasLocationsVisibleOnTheMap}>
                    <SmallLocationCardAlternative1Skeleton />
                    <SmallLocationCardAlternative1Skeleton />
                    <SmallLocationCardAlternative1Skeleton />
                    <SmallLocationCardAlternative1Skeleton />
                </Hidden>
                <Hidden isShowing={!isLoading && !hasLocationsVisibleOnTheMap}>
                    <div className={discoverMapListEmptyStyle}>
                        Pan or zoom out to view more available locations.
                    </div>
                </Hidden>
                <Hidden
                    isShowing={hasLocationsVisibleOnTheMap}
                    className={discoverMapListCardsWrapperStyle}
                >
                    {sortedLocations
                        ?.filter((location) => location.isMatch)
                        .slice(0, MAX_NUMBER_OF_LOCATIONS_TO_DISPLAY)
                        .map((location, index) => {
                            return (
                                <LazyLoad
                                    height={310}
                                    key={location.location.id}
                                >
                                    <DiscoverMapListLocationCard
                                        hostLocationWithWorkspaceGroups={
                                            location
                                        }
                                        // Load the first 4 cards immediately.
                                        priority={index < 4}
                                    />
                                </LazyLoad>
                            );
                        })}
                </Hidden>
            </Flex>
        </Flex>
    );
};

export default DiscoverMapList;

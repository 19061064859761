import { TSimplifiedHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/SimplifiedHostLocationWithWorkspaceGroupsSchema";
import { RatingsType } from "@/features/host-locations/domain/entities/RatingsSchema";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
    faMoneyBill,
    faPercent,
    faStar,
} from "@fortawesome/free-solid-svg-icons";
import { parseAsStringLiteral } from "nuqs";

export const sortTypes = [
    "deal",
    "price-high-to-low",
    "price-low-to-high",
    "rating",
] as const;
export type SortType = (typeof sortTypes)[number];
export const sortOptions: {
    label: string;
    type: (typeof sortTypes)[number];
    icon: IconDefinition;
    sortFn: ({
        hours,
    }: {
        hours: number;
    }) => (
        a: TSimplifiedHostLocationWithWorkspaceGroupsSchema,
        b: TSimplifiedHostLocationWithWorkspaceGroupsSchema
    ) => number;
}[] = [
    {
        label: "Deals",
        type: "deal",
        icon: faPercent,
        sortFn: () => (a, b) => {
            return b.location.discounts.length - a.location.discounts.length;
        },
    },
    {
        label: "Price high to low",
        type: "price-high-to-low",
        icon: faMoneyBill,
        sortFn:
            ({ hours }) =>
            (a, b) => {
                const aPrice = a.workspace_groups.reduce((acc, group) => {
                    if (!group.pricingInformation || !group.isMatch) {
                        return acc;
                    }

                    const rawPricing =
                        group.pricingInformation.lowestPrice.totalPrice *
                        (group.pricingInformation.lowestPrice.intervalLength ===
                        "HOUR"
                            ? hours
                            : 1);

                    if (!acc) {
                        return rawPricing;
                    }
                    return Math.max(acc, rawPricing);
                }, Number.MIN_VALUE);
                const bPrice = b.workspace_groups.reduce((acc, group) => {
                    if (!group.pricingInformation || !group.isMatch) {
                        return acc;
                    }

                    const rawPricing =
                        group.pricingInformation.lowestPrice.totalPrice *
                        (group.pricingInformation.lowestPrice.intervalLength ===
                        "HOUR"
                            ? hours
                            : 1);

                    if (!acc) {
                        return rawPricing;
                    }
                    return Math.max(acc, rawPricing);
                }, Number.MIN_VALUE);
                if (!aPrice || !bPrice) {
                    return 0;
                }
                return bPrice - aPrice;
            },
    },
    {
        label: "Price low to high",
        type: "price-low-to-high",
        icon: faMoneyBill,
        sortFn:
            ({ hours }) =>
            (a, b) => {
                const aPrice = a.workspace_groups.reduce((acc, group) => {
                    if (!group.pricingInformation || !group.isMatch) {
                        return acc;
                    }

                    const rawPricing =
                        group.pricingInformation.lowestPrice.totalPrice *
                        (group.pricingInformation.lowestPrice.intervalLength ===
                        "HOUR"
                            ? hours
                            : 1);

                    if (!acc) {
                        return rawPricing;
                    }
                    return Math.min(acc, rawPricing);
                }, Number.MAX_VALUE);
                const bPrice = b.workspace_groups.reduce((acc, group) => {
                    if (!group.pricingInformation || !group.isMatch) {
                        return acc;
                    }

                    const rawPricing =
                        group.pricingInformation.lowestPrice.totalPrice *
                        (group.pricingInformation.lowestPrice.intervalLength ===
                        "HOUR"
                            ? hours
                            : 1);

                    if (!acc) {
                        return rawPricing;
                    }
                    return Math.min(acc, rawPricing);
                }, Number.MAX_VALUE);
                if (!aPrice || !bPrice) {
                    return 0;
                }
                return aPrice - bPrice;
            },
    },
    {
        label: "Rating",
        type: "rating",
        icon: faStar,
        sortFn: () => (a, b) => {
            const aRating = a.location.ratings?.find(
                (rating) => rating.type === RatingsType.overall
            );
            const bRating = b.location.ratings?.find(
                (rating) => rating.type === RatingsType.overall
            );
            return (bRating?.value || 0) - (aRating?.value || 0);
        },
    },
];
export const sortParser = parseAsStringLiteral<(typeof sortTypes)[number]>(
    sortOptions.map((v) => v.type)
).withOptions({
    history: "push",
});

"use client";

import { useDiscoverMapStore } from "@/app/(main)/discover/[[...params]]/(application)/useDiscoverMapStore";
import DiscoverMapListLocationCard from "@/app/(main)/discover/[[...params]]/(components)/(cards)/DiscoverMapListLocationCard";
import DiscoverMapTooltip from "@/app/(main)/discover/[[...params]]/(components)/(cards)/DiscoverMapTooltip";
import {
    discoverMapStyle,
    discoverMapToggleCollapsedSidebarStyle,
    discoverMapToggleViewButtonStyle,
} from "@/app/(main)/discover/[[...params]]/(components)/(map)/DiscoverMap.css";
import {
    mapQueryStateHostKey,
    mapQueryStateLatitudeKey,
    mapQueryStateLongitudeKey,
    mapQueryStateNamedLocationKey,
    mapQueryStateZoomKey,
} from "@/app/(main)/discover/[[...params]]/(components)/(map)/mapQueryStateKeys";
import {
    discoverMapListSplitViewMobileMaxWidthInPx,
    discoverMapListSplitViewSidebarWidthInPx,
} from "@/app/(main)/discover/[[...params]]/(components)/discover-map-constants";
import { useDiscoverMapContext } from "@/app/(main)/discover/[[...params]]/(components)/DiscoverMapProvider";
import { DiscoverViewSwitch } from "@/app/(main)/discover/[[...params]]/(components)/DiscoverViewSwitch";
import { HostLocationPageApi } from "@/app/(main)/discover/host/[hostLocationId]/pageApi";
import { reportInfo } from "@/common/application/debug";
import { hasValue } from "@/common/utilities/hasValue";
import { roundToDecimals } from "@/common/utilities/math/roundToDecimals";
import Button from "@/component-library/components/buttons/button/Button";
import { smallLocationCardAlternative1Style } from "@/component-library/components/cards/location-cards/small-location-card-alternative-1/SmallLocationCardAlternative1.css";
import Hidden from "@/component-library/components/layout/hidden/Hidden";
import { useSplitViewStore } from "@/component-library/components/layout/split-view/application/SplitViewStoreContext";
import { splitViewToggleCollapsedSidebarButtonStyle } from "@/component-library/components/layout/split-view/SplitView.css";
import { AMSTERDAM_LONG_LAT } from "@/component-library/components/map-reusable/createReusableMapStore";
import {
    InnerReusableMap,
    ReusableMapImperativeHandle,
    TMapStateWithBounds,
} from "@/component-library/components/map-reusable/ReusableMap";
import ReusableMapMarker from "@/component-library/components/map-reusable/ReusableMapMarker";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import { ZIndex } from "@/component-library/constants/ZIndex";
import { theme } from "@/component-library/themes/theme.css";
import {
    WezooGeoConfig,
    WezooMapConfig,
} from "@/configs/discover/WezooMapConfig";
import { useFilteredHostLocations } from "@/features/filtering/application/useFilteredHostLocations";
import { convertParamToGeographicLocation } from "@/features/host-locations/application/convertParamToGeographicLocation";
import { useSingleHostLocation } from "@/features/host-locations/application/useSingleHostLocation";
import { GeographicCoordinate } from "@/features/host-locations/domain/entities/GeographicCoordinate";
import { TSimplifiedHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/SimplifiedHostLocationWithWorkspaceGroupsSchema";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { useSearchParams } from "next/navigation";
import { parseAsFloat, parseAsString, useQueryState } from "nuqs";
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { useDebounceCallback, useMediaQuery } from "usehooks-ts";
import { create, useStore } from "zustand";

interface DiscoverMapProps {}

interface MarkerData {
    show: boolean;
    stale: boolean;
    location: TSimplifiedHostLocationWithWorkspaceGroupsSchema;
}

const useMarkerStore = create<{
    markerData: Record<string, MarkerData>;
    setMarkerData: (markerId: string, data: MarkerData) => void;
    setAllStale: () => void;
    hideAllStale: () => void;
}>((set) => ({
    markerData: {},
    setMarkerData: (markerId, data) =>
        set((state) => ({
            markerData: { ...state.markerData, [markerId]: data },
        })),
    setAllStale: () =>
        set((state) => ({
            markerData: Object.fromEntries(
                Object.entries(state.markerData).map(([key, value]) => [
                    key,
                    { ...value, stale: true },
                ])
            ),
        })),
    hideAllStale: () =>
        set((state) => ({
            markerData: Object.fromEntries(
                Object.entries(state.markerData).map(([key, value]) => [
                    key,
                    {
                        ...value,
                        show: value.show && !value.stale,
                        stale: false,
                    },
                ])
            ),
        })),
}));

const DiscoverMap = forwardRef<ReusableMapImperativeHandle, DiscoverMapProps>(
    (_, refIn) => {
        const { markerData, setMarkerData, setAllStale, hideAllStale } =
            useStore(useMarkerStore);

        const loadingBarRef = useRef<null | LoadingBarRef>(null);
        const [startedLoading, setStartedLoading] = useState(false);

        const [longitude, setLongitude] = useQueryState(
            mapQueryStateLongitudeKey,
            parseAsFloat
        );
        const [latitude, setLatitude] = useQueryState(
            mapQueryStateLatitudeKey,
            parseAsFloat
        );
        const [zoom, setZoom] = useQueryState(
            mapQueryStateZoomKey,
            parseAsFloat
        );
        const [mapLocationFromQueryParam, setMapLocationFromQueryParam] =
            useQueryState(mapQueryStateNamedLocationKey, parseAsString);
        const [hostLocationIdFromQueryParams] = useQueryState(
            mapQueryStateHostKey,
            parseAsString
        );

        const queryParams = useSearchParams();

        const setSidebarExpanded = useSplitViewStore(
            (state) => state.setSidebarExpanded
        );
        const isSidebarExpanded = useSplitViewStore(
            (state) => state.isSidebarExpanded
        );

        const showAtBottom = useSplitViewStore((state) => state.showAtBottom);
        const hideComponentShownAtBottom = useSplitViewStore(
            (state) => state.hideComponentShownAtBottom
        );

        // For compatibility with everything dependent on the OLD global map store.
        const { setMapBounds, setMapState, mapState } = useDiscoverMapStore(
            (state) => state
        );
        const highlightedHostLocationId = useDiscoverMapStore(
            (state) => state.highlightedHostLocationId
        );
        const setHighlightedHostLocationId = useDiscoverMapStore(
            (state) => state.setHighlightedHostLocationId
        );

        const isMobile = useMediaQuery(
            `screen and (max-width: ${discoverMapListSplitViewMobileMaxWidthInPx}px)`,
            { initializeWithValue: true }
        );

        const mapRef = useRef<ReusableMapImperativeHandle | null>(null);

        const [hostLocationId, setHostLocationId] = useState<string | null>(
            null
        );
        const { data: targetHostLocationToMoveTo } =
            useSingleHostLocation(hostLocationId);

        const { hostLocations, isLoading, hostLocationError } =
            useDiscoverMapContext();
        const { filteredLocations } = useFilteredHostLocations({
            locations: hostLocations,
        });
        //const debouncedIsLoading = useDebounceValue(isLoading, 1000);

        // reportInfo("[DiscoverMap] isLoading", isLoading);

        // Filtered locations will start growing as new pages are loaded. We need to update the
        // marker data when this happens. When the filtered locations is done loading, we need to
        // we need to hide all stale markers. When the filtered locations start loading, we need to
        // set all markers to stale. Any marker that is not in the filtered locations should be
        // hidden. Any marker that is in the filtered locations should be shown.
        useEffect(() => {
            if (filteredLocations) {
                reportInfo(
                    "[DiscoverMap] Updating markers for new page, total:",
                    filteredLocations.length
                );
                for (const location of filteredLocations) {
                    setMarkerData(location.location.id, {
                        show: location.isMatch,
                        stale: false,
                        location,
                    });
                }
                if (!isLoading) {
                    reportInfo("[DiscoverMap] Hiding all stale markers");
                    hideAllStale();
                }
            }
        }, [filteredLocations, isLoading, setMarkerData, hideAllStale]);

        useEffect(() => {
            reportInfo("[DiscoverMap] Setting all markers to stale");
            setAllStale();
        }, [setAllStale, queryParams]);

        const markers = useMemo(() => {
            return Object.values(markerData).map((markerDatum) => {
                const { location } = markerDatum;
                return (
                    <ReusableMapMarker
                        key={location.location.id}
                        isInactive={
                            (hasValue(highlightedHostLocationId) &&
                                highlightedHostLocationId !==
                                    location.location.id) ||
                            markerDatum.stale
                        }
                        zIndex={ZIndex.overlay}
                        inactiveZIndex={0}
                        coordinate={
                            new GeographicCoordinate({
                                latitude: location.location.latitude,
                                longitude: location.location.longitude,
                            })
                        }
                        isShowing={markerDatum.show}
                        removeTooltipStyles
                        tooltip={
                            <Hidden isShowing={!isMobile}>
                                <DiscoverMapTooltip
                                    hostLocationWithWorkspaceGroups={location}
                                />
                            </Hidden>
                        }
                        linkProps={
                            isMobile
                                ? undefined
                                : {
                                      href: HostLocationPageApi.getRoute({
                                          hostLocationId:
                                              location.location.slug,
                                          params: Object.fromEntries(
                                              queryParams.entries()
                                          ),
                                      }),
                                      target: "_blank",
                                  }
                        }
                        onShowTooltip={
                            isMobile
                                ? undefined
                                : () => {
                                      setHighlightedHostLocationId(
                                          location.location.id
                                      );
                                  }
                        }
                        onHideTooltip={
                            isMobile
                                ? undefined
                                : () => setHighlightedHostLocationId(null)
                        }
                        onClick={
                            isMobile
                                ? () => {
                                      if (
                                          highlightedHostLocationId ===
                                          location.location.id
                                      ) {
                                          hideComponentShownAtBottom();
                                          setHighlightedHostLocationId(null);

                                          return;
                                      }

                                      setHighlightedHostLocationId(
                                          location.location.id
                                      );
                                      showAtBottom(
                                          <div
                                              style={assignInlineVars({
                                                  width: `min(${discoverMapListSplitViewSidebarWidthInPx}px, 95vw)`,
                                              })}
                                          >
                                              <DiscoverMapListLocationCard
                                                  hostLocationWithWorkspaceGroups={
                                                      location
                                                  }
                                                  doNotChangeHighlightedHostLocationIdOnHover
                                              />
                                          </div>
                                      );
                                  }
                                : undefined
                        }
                        onClickOutside={() => {
                            if (
                                highlightedHostLocationId ===
                                location.location.id
                            ) {
                                hideComponentShownAtBottom();
                                setHighlightedHostLocationId(null);
                            }
                        }}
                        ignoreOnClickOutsideWhenClickedElementContainsClass={[
                            smallLocationCardAlternative1Style,
                        ]}
                    />
                );
            });
        }, [
            markerData,
            // filteredLocations,
            hideComponentShownAtBottom,
            highlightedHostLocationId,
            isMobile,
            queryParams,
            setHighlightedHostLocationId,
            showAtBottom,
        ]);

        const mapOptions = useMemo(() => {
            return {
                mapConfig: WezooMapConfig,
                isInteractive: true,

                useOnlyZoomButtons: false,
                minZoom: 6,
                maxZoom: 18,
            };
        }, []);

        const initialView = useMemo(() => {
            return {
                initialLocation: new GeographicCoordinate({
                    latitude:
                        mapState?.currentPosition.latitude ??
                        latitude ??
                        AMSTERDAM_LONG_LAT.latitude,
                    longitude:
                        mapState?.currentPosition.longitude ??
                        longitude ??
                        AMSTERDAM_LONG_LAT.longitude,
                }),
                initialZoom: mapState?.zoom ?? zoom ?? 7,
            };
            // Initial view is only relevant on first load; it does not need to be updated
            // afterward.
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [mapState]);

        const onLoad = useCallback(() => {
            const helper = async () => {
                if (hostLocationIdFromQueryParams) {
                    setHostLocationId(hostLocationIdFromQueryParams);

                    return;
                }

                if (mapLocationFromQueryParam && (!latitude || !longitude)) {
                    const geographicLocation =
                        await convertParamToGeographicLocation(
                            mapLocationFromQueryParam
                        );
                    reportInfo("geographicLocation", geographicLocation);
                    if (geographicLocation) {
                        // setQuery(mapLocationFromQueryParam);
                        void mapRef.current?.setTo(
                            geographicLocation.coordinates,
                            geographicLocation.zoom
                        );
                        if (geographicLocation.bbox) {
                            setMapBounds({
                                minLatitude: geographicLocation.bbox[1],
                                minLongitude: geographicLocation.bbox[0],
                                maxLatitude: geographicLocation.bbox[3],
                                maxLongitude: geographicLocation.bbox[2],
                            });
                        } else {
                            reportInfo(
                                "geographicLocation.coordinates",
                                geographicLocation.coordinates
                            );
                            const coords =
                                geographicLocation.coordinates.getCoordinate();
                            setMapBounds({
                                minLatitude:
                                    coords.latitude -
                                    WezooGeoConfig.bboxFromPointOffset,
                                minLongitude:
                                    coords.longitude -
                                    WezooGeoConfig.bboxFromPointOffset,
                                maxLatitude:
                                    coords.latitude +
                                    WezooGeoConfig.bboxFromPointOffset,
                                maxLongitude:
                                    coords.longitude +
                                    WezooGeoConfig.bboxFromPointOffset,
                            });
                        }
                        void setMapLocationFromQueryParam(null);
                    }
                }
            };

            void helper();

            /**
             * We only care about changes in query params.
             */
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hostLocationIdFromQueryParams, mapLocationFromQueryParam]);

        const onMapStateChange = useCallback(
            (mapState: TMapStateWithBounds) => {
                /**
                 * HAS TO be a memoized callback. Otherwise, the reference to the function changes
                 * on every component re-render, and debouncing within the component won't work.
                 */
                setMapBounds(mapState.viewBounds);
                setMapState(mapState);

                void setLatitude(
                    roundToDecimals(mapState.currentPosition.latitude, 2)
                );
                void setLongitude(
                    roundToDecimals(mapState.currentPosition.longitude, 2)
                );
                void setZoom(roundToDecimals(mapState.zoom, 2));
            },
            [setLatitude, setLongitude, setMapBounds, setMapState, setZoom]
        );

        useLayoutEffect(() => {
            if (!hasValue(targetHostLocationToMoveTo)) {
                return;
            }

            void mapRef.current?.moveTo(
                new GeographicCoordinate({
                    latitude: targetHostLocationToMoveTo.location.latitude,
                    longitude: targetHostLocationToMoveTo.location.longitude,
                }),
                15
            );
        }, [targetHostLocationToMoveTo]);

        useEffect(() => {
            if (isLoading && !startedLoading) {
                loadingBarRef.current?.continuousStart();
                setStartedLoading(true);
            }
            if (!isLoading && startedLoading) {
                loadingBarRef.current?.complete();
                setStartedLoading(false);
            }
        }, [isLoading, startedLoading, hostLocations]);

        /**
         * Avoid accidentally zooming in when closing the split view bottom component, by enablind
         * double tap zoom only after a short delay.
         */
        const [isDoubleClickZoomEnabled, _setIsDoubleClickZoomEnabled] =
            useState(true);
        const _setIsDoubleClickZoomEnabledDebounced = useDebounceCallback(
            _setIsDoubleClickZoomEnabled,
            350
        );
        const setIsDoubleClickZoomEnabled = useCallback(
            (newValue: boolean) => {
                if (newValue) {
                    _setIsDoubleClickZoomEnabledDebounced(true);
                } else {
                    _setIsDoubleClickZoomEnabled(false);
                }
            },
            [_setIsDoubleClickZoomEnabledDebounced]
        );
        useEffect(() => {
            setIsDoubleClickZoomEnabled(
                !isMobile || !hasValue(highlightedHostLocationId)
            );
        }, [highlightedHostLocationId, isMobile, setIsDoubleClickZoomEnabled]);

        if (hostLocationError) {
            throw hostLocationError;
        }

        useImperativeHandle(refIn, () => mapRef.current!, [mapRef]);

        return (
            <div data-cy="discover-map" className={discoverMapStyle}>
                <div data-cy="discover-map-loading-bar">
                    <LoadingBar
                        color={theme.colors.semantic.interactive.lighter}
                        ref={loadingBarRef}
                        shadow={false}
                    />
                </div>
                <InnerReusableMap
                    key="discover-map"
                    ref={mapRef}
                    onLoad={onLoad}
                    onMapStateChange={onMapStateChange}
                    mapOptions={mapOptions}
                    initialView={initialView}
                    rawMapProps={{
                        doubleClickZoom: isDoubleClickZoomEnabled,
                        onDrag: () => {
                            setHighlightedHostLocationId(null);
                            hideComponentShownAtBottom();
                        },
                    }}
                >
                    {markers}
                </InnerReusableMap>
                <div className={discoverMapToggleViewButtonStyle}>
                    <DiscoverViewSwitch />
                </div>
                <Hidden isShowing={isMobile && !isSidebarExpanded}>
                    <div className={discoverMapToggleCollapsedSidebarStyle}>
                        <Button
                            className={
                                splitViewToggleCollapsedSidebarButtonStyle
                            }
                            onClick={() => {
                                setSidebarExpanded(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </Button>
                    </div>
                </Hidden>
            </div>
        );
    }
);

DiscoverMap.displayName = "DiscoverMap";

export default DiscoverMap;

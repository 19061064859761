import { filterProps } from "@/common/application/components/filterProps";
import { reportInfo } from "@/common/application/debug";
import {
    dateTimeFilterDefaultEndTime,
    dateTimeFilterDefaultStartTime,
} from "@/features/filtering/filters/date-time-filter/date-time-filter-constants";
import {
    reactDateTimePickerActiveDateStyle,
    reactDateTimePickerInactiveDateStyle,
    reactDateTimePickerStyle,
} from "@/features/filtering/filters/date-time-filter/DateTimeFilterStyles.css";
import { clsx } from "clsx";
import moment, { Moment } from "moment/moment";
import React from "react";
import ReactDatetimePicker from "react-datetime";

interface DateTimePickerProps {
    startDateTime: Date | null;
    endDateTime: Date | null;
    setStartDateTime: (date: Date) => void;
    setEndDateTime: (date: Date) => void;
}

const DateTimeFilterDatePicker: React.FC<DateTimePickerProps> = ({
    startDateTime,
    endDateTime,
    setStartDateTime,
    setEndDateTime,
}) => {
    return (
        <ReactDatetimePicker
            // Always re-render the [ReactDatetimePicker] on component (re)mount, to avoid rapid
            // filter reset before store has updated resulting in stale data, and having
            // [ReactDatetimePicker] persist its old date. TODO: fix with a more elegant solution
            key={`(re-)rendered-at-${new Date().getTime()}`}
            className={clsx(reactDateTimePickerStyle)}
            timeFormat={false}
            closeOnSelect
            closeOnClickOutside
            input={false}
            initialValue={startDateTime ?? undefined}
            onChange={(selectedDate) => {
                const rawDate = moment(selectedDate).toDate();
                const newStart = new Date(
                    rawDate.getFullYear(),
                    rawDate.getMonth(),
                    rawDate.getDate(),
                    startDateTime?.getHours() ?? dateTimeFilterDefaultStartTime,
                    startDateTime?.getMinutes() ?? 0,
                    0,
                    0
                );
                const newEnd = new Date(
                    rawDate.getFullYear(),
                    rawDate.getMonth(),
                    rawDate.getDate(),
                    endDateTime?.getHours() ?? dateTimeFilterDefaultEndTime,
                    endDateTime?.getMinutes() ?? 0,
                    0,
                    0
                );

                reportInfo("DateTimeFilterDatePicker", {
                    newStart,
                    newEnd,
                });
                setStartDateTime(newStart);
                setEndDateTime(newEnd);
            }}
            renderDay={(
                { key, className, ...restProps }: any,
                currentDate: Moment
            ) => {
                const inactiveProps = filterProps(
                    restProps,
                    (prop) => !["onClick", "className", "style"].includes(prop)
                );

                const newKey = key ?? `td-${currentDate.date()}`;

                const today = new Date();
                today.setHours(0);
                today.setMinutes(0);
                today.setSeconds(0);
                today.setMilliseconds(0);

                const allowBooking = currentDate.isSameOrAfter(today, "day");

                if (!allowBooking) {
                    return (
                        <td
                            key={newKey}
                            data-cy={`inactive-date`}
                            className={clsx(
                                reactDateTimePickerInactiveDateStyle,
                                className
                            )}
                            {...inactiveProps}
                        >
                            {currentDate.date()}
                        </td>
                    );
                }

                return (
                    <td
                        key={newKey}
                        data-cy={`active-date`}
                        className={clsx(
                            reactDateTimePickerActiveDateStyle,
                            className
                        )}
                        {...restProps}
                    >
                        {currentDate.date()}
                    </td>
                );
            }}
            renderView={(viewMode, renderCalendar) => {
                return renderCalendar();
            }}
        />
    );
};

export default DateTimeFilterDatePicker;

"use client";

import Button from "@/component-library/components/buttons/button/Button";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { resourceTypeFilterAppBarComponentContentStyle } from "@/features/filtering/filters/resource-type-filter/resourceTypeFilterAppBarComponent.css";
import { useResourceTypeFilter } from "@/features/filtering/filters/resource-type-filter/useResourceTypeFilter";
import { faUser, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import React from "react";

interface ResourceTypeFilterAppBarComponentProps {}

const ResourceTypeFilterAppBarComponent: React.FC<
    ResourceTypeFilterAppBarComponentProps
> = () => {
    const { active, setActive } = useResourceTypeFilter();
    // const hasActiveFilters = active.length > 0;
    // const label = hasActiveFilters
    //     ? active
    //           .map((type) => {
    //               switch (type) {
    //                   case "ROOM":
    //                       return "Room";
    //                   case "HOTDESK":
    //                       return "Hot Desk";
    //               }
    //           })
    //           .join(" or ")
    //     : "Workspace type";

    // Instead, we'll show a rounded button for each filter next to each other
    return (
        <Flex
            direction={"row"}
            style={assignInlineVars({
                marginTop: `${Magnitudes.spacingInRem.s}rem`,
            })}
        >
            <Button
                data-cy="meeting-rooms-filter-button"
                contentClassName={resourceTypeFilterAppBarComponentContentStyle}
                variant={
                    !active.length || active.includes("ROOM")
                        ? "roundedFilled"
                        : "rounded"
                }
                onClick={
                    active.includes("ROOM")
                        ? () => void setActive(null)
                        : () => void setActive(["ROOM"])
                }
            >
                <FontAwesomeIcon icon={faUserGroup} />
                Meeting rooms
            </Button>

            <Button
                contentClassName={resourceTypeFilterAppBarComponentContentStyle}
                variant={
                    !active.length || active.includes("HOTDESK")
                        ? "roundedFilled"
                        : "rounded"
                }
                onClick={
                    active.includes("HOTDESK")
                        ? () => void setActive(null)
                        : () => void setActive(["HOTDESK"])
                }
            >
                <FontAwesomeIcon icon={faUser} />
                Hot Desks
            </Button>
        </Flex>
    );

    // return (
    //     <AppBarFilter
    //         filterId={resourceTypeFilter.id}
    //         isActive={active.length > 0}
    //         onRemove={() => {
    //             void setActive(null);
    //         }}
    //         popover={<ResourceTypeFilterAppBarPopover />}
    //         removePadding
    //     >
    //         <FontAwesomeIcon icon={faBuildingUser} />
    //         {label}
    //     </AppBarFilter>
    // );
};

export default ResourceTypeFilterAppBarComponent;

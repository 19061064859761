import { ignore } from "@/common/application/ignore";
import { hasValue } from "@/common/utilities/hasValue";
import { useValueRef } from "@/component-library/animation/useValueRef";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import { clampAndSnapValueToStepSize } from "@/component-library/components/user-input/time-input-field/clampAndSnapValueToStepSize";
import {
    timeInputFieldButtonStyle,
    timeNumberInputStyle,
} from "@/component-library/components/user-input/time-input-field/TimeNumberInput.css";
import { zeroPrefixedNumberToNumber } from "@/component-library/components/user-input/time-input-field/zeroPrefixedNumberToNumber";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import React, { useCallback, useEffect } from "react";

interface TimeNumberInputProps {
    name: string;
    label: string;

    defaultValue: number;
    value?: number;

    min: number;
    max: number;
    step?: number;
    wrapAround?: boolean;

    onChange?: (value: number | null) => void;
    disableTyping?: boolean;
}

const TimeNumberInput: React.FC<TimeNumberInputProps> = ({
    name,
    label,
    defaultValue,
    value,
    min,
    max,
    step = 1,
    wrapAround = false,
    disableTyping = false,
    onChange,
}) => {
    const {
        ref: valueRef,
        set: setValue,
        state: valueState,
    } = useValueRef(defaultValue.toString().padStart(2, "0"));

    const resetValueToDefault = useCallback(() => {
        setValue(defaultValue.toString().padStart(2, "0"));
    }, [defaultValue, setValue]);

    const updateValue = (
        direction: "up" | "down" | null,
        inputValue?: string
        // eslint-disable-next-line sonarjs/cognitive-complexity
    ) => {
        const currentValue = zeroPrefixedNumberToNumber(valueRef.current);

        if (!hasValue(currentValue)) {
            resetValueToDefault();
            return;
        }

        if (direction === "up") {
            if (wrapAround && currentValue === max) {
                setValue(min.toString().padStart(2, "0"));
                return;
            }

            if (currentValue < max) {
                setValue(
                    Math.min(max, currentValue + step)
                        .toString()
                        .padStart(2, "0")
                );
            }
        } else if (direction === "down") {
            if (wrapAround && currentValue === min) {
                setValue(max.toString().padStart(2, "0"));
                return;
            }

            if (currentValue > min) {
                setValue(
                    Math.max(min, currentValue - step)
                        .toString()
                        .padStart(2, "0")
                );
            }
        } else if (hasValue(inputValue)) {
            const valueAsNumber = parseInt(inputValue);

            if (isNaN(valueAsNumber)) {
                setValue(inputValue.slice(0, 2));
                return;
            }

            // if (wrapAround && valueAsNumber > max) {
            //     valueAsNumber = min;
            // }
            //
            // if (wrapAround && valueAsNumber < min) {
            //     valueAsNumber = max;
            // }

            const newValue = Math.min(max, Math.max(min, valueAsNumber))
                .toString()
                .padStart(2, "0");

            setValue(newValue);
        }
    };

    const clampAndSnapFieldValueToStepSize = useCallback(
        (valueToClampAndSnap: string) => {
            const newValue = clampAndSnapValueToStepSize(valueToClampAndSnap, {
                min,
                max,
                step,
            });

            if (!hasValue(newValue)) {
                resetValueToDefault();
                return;
            }

            setValue(newValue);
        },
        [max, min, resetValueToDefault, setValue, step]
    );

    useEffect(() => {
        if (hasValue(value)) {
            setValue(value.toString().trim().padStart(2, "0"));
        }
    }, [setValue, value]);

    useEffect(() => {
        ignore(valueState);

        const currentValue = zeroPrefixedNumberToNumber(valueRef.current);
        onChange?.(currentValue);
    }, [onChange, valueRef, valueState]);

    return (
        <>
            <button
                data-cy="time-input-field-button-up"
                className={timeInputFieldButtonStyle}
                onClick={() => updateValue("up")}
            >
                <FontAwesomeIcon icon={faAngleUp} />
            </button>

            <label>
                <VisuallyHidden>{label}</VisuallyHidden>
                <input
                    data-cy="time-input-field-input"
                    type="text"
                    inputMode="numeric"
                    name={name}
                    value={valueState}
                    pattern="[0-9]*"
                    step={step}
                    min={min - (wrapAround ? step : 0)}
                    max={max + (wrapAround ? step : 0)}
                    className={timeNumberInputStyle}
                    onChange={(event) => {
                        updateValue(null, event.currentTarget.value);
                    }}
                    onBlur={(event) => {
                        clampAndSnapFieldValueToStepSize(
                            event.currentTarget.value
                        );
                    }}
                    disabled={disableTyping}
                    onFocus={(event) => {
                        event.currentTarget.select();
                    }}
                />
            </label>

            <button
                data-cy="time-input-field-button-down"
                className={timeInputFieldButtonStyle}
                onClick={() => updateValue("down")}
            >
                <FontAwesomeIcon icon={faAngleDown} />
            </button>
        </>
    );
};

export default TimeNumberInput;

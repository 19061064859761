import Flex from "@/component-library/components/organising-content/flex/Flex";
import {
    timeInputFieldSeparatorStyle,
    timeInputFieldStyle,
    timeInputFieldSubEntryWrapperStyle,
} from "@/component-library/components/user-input/time-input-field/TimeInputField.css";
import TimeNumberInput from "@/component-library/components/user-input/time-input-field/TimeNumberInput";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import React, { FC } from "react";

export interface TimeInputFieldHandle {
    setValuesTo(value: [number, number]): void;
}

interface TimeInputFieldProps {
    value?: [number, number] | null;

    fieldId: string;

    defaultHourValue?: number;
    defaultMinuteValue?: number;

    hoursToReserveAtEnd?: number;
    hoursToReserveAtStart?: number;

    disableTyping?: boolean;

    onChange?: (newValue: [number, number] | null) => void;
}

const TimeInputField: FC<TimeInputFieldProps> = ({
    value,
    fieldId,
    defaultHourValue = 9,
    defaultMinuteValue = 0,
    hoursToReserveAtEnd,
    hoursToReserveAtStart,
    disableTyping = false,
    onChange,
}) => {
    return (
        <Flex
            direction="row"
            alignItems="center"
            justifyContent="stretch"
            gap={Magnitudes.spacingInRem.xxs}
            className={timeInputFieldStyle}
        >
            <div className={timeInputFieldSubEntryWrapperStyle}>
                <TimeNumberInput
                    defaultValue={defaultHourValue}
                    //value={valueRef.current[0] ?? undefined}
                    value={value ? value[0] : defaultHourValue}
                    name={`${fieldId}-start-time-minutes`}
                    label="hours"
                    min={hoursToReserveAtStart ?? 0}
                    max={23 - (hoursToReserveAtEnd ?? 0)}
                    step={1}
                    disableTyping={disableTyping}
                    onChange={(newValue) => {
                        onChange?.([
                            newValue ?? defaultHourValue,
                            value ? value[1] : defaultMinuteValue,
                        ]);
                    }}
                />
            </div>
            <div className={timeInputFieldSeparatorStyle}>:</div>
            <div className={timeInputFieldSubEntryWrapperStyle}>
                <TimeNumberInput
                    defaultValue={defaultMinuteValue}
                    value={value ? value[1] : defaultMinuteValue}
                    name={`${fieldId}-end-time-minutes`}
                    label="minutes"
                    min={0}
                    max={30}
                    step={30}
                    disableTyping={disableTyping}
                    onChange={(newValue) => {
                        onChange?.([
                            value ? value[0] : defaultHourValue,
                            newValue ?? defaultMinuteValue,
                        ]);
                    }}
                />
            </div>
        </Flex>
    );
};

TimeInputField.displayName = "TimeInputField";

export default TimeInputField;

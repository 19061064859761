"use client";

import { reportAnalyticsEvent } from "@/features/analytics/application/reportAnalyticsEvent";
import { AnalyticsMetadataKeys } from "@/features/analytics/domain/constants/AnalyticsMetadataKeys";
import { AnalyticsEventType } from "@/features/analytics/domain/entities/AnalyticsEventType";
import { anonymizeUserId } from "@/features/analytics/domain/entities/AnalyticsTools";

type SearchMetadata = Record<
    | typeof AnalyticsMetadataKeys.userId
    | typeof AnalyticsMetadataKeys.query
    | typeof AnalyticsMetadataKeys.resourceType
    | typeof AnalyticsMetadataKeys.durationMinutes
    | typeof AnalyticsMetadataKeys.startTime
    | typeof AnalyticsMetadataKeys.endTime
    | typeof AnalyticsMetadataKeys.latitude
    | typeof AnalyticsMetadataKeys.longitude
    | typeof AnalyticsMetadataKeys.currency
    | typeof AnalyticsMetadataKeys.capacity
    | typeof AnalyticsMetadataKeys.sort,
    string | number | undefined
>;

/**
 * Reports specific conversion events.
 */
export const reportSearch = (
    label: "discover-search" | "host-search" | "city-page",
    metadata: Partial<SearchMetadata>
) => {
    void (async () => {
        // If there is a user id, anonymize it.
        const user_id = metadata[AnalyticsMetadataKeys.userId]
            ? await anonymizeUserId(
                  metadata[AnalyticsMetadataKeys.userId] as string
              )
            : undefined;

        void reportAnalyticsEvent({
            eventType: AnalyticsEventType.search,
            label,
            metadata: {
                ...metadata,
                label,
                ...(user_id ? { [AnalyticsMetadataKeys.userId]: user_id } : {}),
            },
        });
    })();
};

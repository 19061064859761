import { reportInfo } from "@/common/application/debug";
import { millisecondsToSeconds } from "@/common/utilities/date/millisecondsToSeconds";
import { secondsToHours } from "@/common/utilities/date/secondsToHours";
import { hasValue } from "@/common/utilities/hasValue";
import { useValueRef } from "@/component-library/animation/useValueRef";
import Separator from "@/component-library/components/layout/separator/Separator";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import Flex from "@/component-library/components/organising-content/flex/Flex";
import { addSpaceBetweenStyleRecipe } from "@/component-library/components/organising-content/spacing-styles.css";
import TimeInputField, {
    TimeInputFieldHandle,
} from "@/component-library/components/user-input/time-input-field/TimeInputField";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { MeetingRoomConfig } from "@/configs/resources/meeting-room/MeetingRoomConfig";
import DateTimeFilterDatePicker from "@/features/filtering/filters/date-time-filter/DateTimeFilterDatePicker";
import {
    dateTimeFilterExpandingEntryContentStyle,
    dateTimeFilterExpandingEntryStyle,
    dateTimeFilterPopperStyle,
    dateTimeFilterTimeEntryWrapperStyle,
    dateTimeFilterTimeLabelStyle,
    meetingDurationStyle,
    timeSeparatorStyle,
} from "@/features/filtering/filters/date-time-filter/DateTimeFIlterPopper.css";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { clsx } from "clsx";
import React, {
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { animated, easings, useSpring, useSpringRef } from "react-spring";

interface DateTimeFilterPopperProps {
    startDateTime: Date | null;
    endDateTime: Date | null;
    setStartDateTime: (newStart: Date) => void;
    setEndDateTime: (newEnd: Date) => void;
}

const DateTimeFilterPopper: React.FC<DateTimeFilterPopperProps> = ({
    startDateTime,
    endDateTime,
    setStartDateTime,
    setEndDateTime,
}) => {
    const dateTimeFilterExpandingEntryContentRef =
        useRef<HTMLDivElement | null>(null);
    const startDateTimeInputFieldRef = useRef<TimeInputFieldHandle | null>(
        null
    );
    const endDateTimeInputFieldRef = useRef<TimeInputFieldHandle | null>(null);

    const {
        ref: showInvalidTimeWarningRef,
        set: setShowInvalidTimeWarning,
        state: showInvalidTimeWarningState,
    } = useValueRef<{
        invalidStart: boolean;
        invalidEnd: boolean;
    }>({
        invalidEnd: false,
        invalidStart: false,
    });

    const minBookingDurationInHours = useMemo(() => {
        return secondsToHours(MeetingRoomConfig.minBookingDurationInSeconds);
    }, []);

    reportInfo("hasDateSet", startDateTime, endDateTime);
    const hasDateSet = startDateTime && endDateTime;

    const [expandingContentHeight, setExpandingContentHeight] = useState(0);

    const animationConfig = useMemo(
        () => ({
            config: {
                duration: Magnitudes.durationsInS.xl * 1000,
                easing: easings.easeOutExpo,
            },
        }),
        []
    );

    const expandRef = useSpringRef();
    const [expandAnimationVars, expandAnimationApi] = useSpring(
        () => ({
            ref: expandRef,
            config: animationConfig,
            maxHeight: hasDateSet ? expandingContentHeight : 0,
            opacity: hasDateSet ? 1 : 0,
        }),
        [hasDateSet]
    );

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            setExpandingContentHeight(
                dateTimeFilterExpandingEntryContentRef.current?.clientHeight ??
                    0
            );
        });

        if (dateTimeFilterExpandingEntryContentRef.current) {
            observer.observe(dateTimeFilterExpandingEntryContentRef.current);
        }

        return () => observer.disconnect();
    }, []);

    useLayoutEffect(() => {
        if (hasDateSet) {
            void expandAnimationApi.start({
                ...animationConfig,
                maxHeight: expandingContentHeight,
                opacity: 1,
            });
        } else {
            void expandAnimationApi.start({
                ...animationConfig,
                maxHeight: 0,
                opacity: 0,
            });
        }
    }, [
        animationConfig,
        expandAnimationApi,
        expandingContentHeight,
        hasDateSet,
    ]);

    return (
        <div
            data-cy="date-time-filter-popper"
            className={clsx(
                addSpaceBetweenStyleRecipe({ variant: "medium" }),
                dateTimeFilterPopperStyle
            )}
        >
            <DateTimeFilterDatePicker
                startDateTime={startDateTime}
                endDateTime={endDateTime}
                setStartDateTime={setStartDateTime}
                setEndDateTime={setEndDateTime}
            />

            <animated.div
                data-cy="date-time-filter-expanding-entry"
                className={dateTimeFilterExpandingEntryStyle}
                style={expandAnimationVars}
            >
                <div
                    ref={dateTimeFilterExpandingEntryContentRef}
                    className={dateTimeFilterExpandingEntryContentStyle}
                >
                    <Separator variant="light" />

                    <div className={dateTimeFilterTimeEntryWrapperStyle}>
                        <div className={dateTimeFilterTimeLabelStyle}>
                            <Flex
                                direction="row"
                                alignItems="center"
                                gap={Magnitudes.spacingInRem.xxs * 0.75}
                            >
                                <FontAwesomeIcon
                                    icon={faClock}
                                    fontSize={1.5}
                                />{" "}
                                Start time
                            </Flex>
                            <TimeInputField
                                value={[
                                    startDateTime?.getHours() || 9,
                                    startDateTime?.getMinutes() || 0,
                                ]}
                                fieldId="date-time-start"
                                defaultHourValue={
                                    startDateTime?.getHours() ?? 9
                                }
                                defaultMinuteValue={
                                    startDateTime?.getMinutes() ?? 0
                                }
                                hoursToReserveAtEnd={minBookingDurationInHours}
                                hoursToReserveAtStart={
                                    minBookingDurationInHours
                                }
                                disableTyping
                                onChange={(newValue) => {
                                    if (!startDateTime) {
                                        return;
                                    }

                                    const newValueIsNull = !hasValue(newValue);

                                    if (
                                        newValueIsNull !==
                                        showInvalidTimeWarningRef.current
                                            .invalidStart
                                    ) {
                                        setShowInvalidTimeWarning({
                                            ...showInvalidTimeWarningRef.current,
                                            invalidStart: newValueIsNull,
                                        });
                                    }

                                    if (newValueIsNull) {
                                        return;
                                    }

                                    const newStart = new Date(
                                        startDateTime.getTime()
                                    );
                                    newStart.setHours(newValue[0]);
                                    newStart.setMinutes(newValue[1]);
                                    newStart.setSeconds(0);
                                    newStart.setMilliseconds(0);

                                    if (
                                        newStart.getTime() ===
                                        startDateTime.getTime()
                                    ) {
                                        return;
                                    }

                                    if (
                                        !endDateTime ||
                                        secondsToHours(
                                            millisecondsToSeconds(
                                                endDateTime.getTime() -
                                                    newStart.getTime()
                                            )
                                        ) < minBookingDurationInHours
                                    ) {
                                        const newEnd = new Date(
                                            newStart.getTime()
                                        );
                                        newEnd.setHours(
                                            newStart.getHours() +
                                                minBookingDurationInHours
                                        );

                                        void setEndDateTime(newEnd);
                                        endDateTimeInputFieldRef.current?.setValuesTo(
                                            [
                                                newEnd.getHours(),
                                                newEnd.getMinutes(),
                                            ]
                                        );
                                    }

                                    void setStartDateTime(newStart);
                                }}
                            />
                        </div>
                        <div className={timeSeparatorStyle}>–</div>
                        <div className={dateTimeFilterTimeLabelStyle}>
                            <Flex
                                direction="row"
                                alignItems="center"
                                gap={Magnitudes.spacingInRem.xxs * 0.75}
                            >
                                <FontAwesomeIcon
                                    icon={faClock}
                                    fontSize={1.5}
                                />{" "}
                                End time
                            </Flex>
                            <TimeInputField
                                value={[
                                    endDateTime?.getHours() || 17,
                                    endDateTime?.getMinutes() || 0,
                                ]}
                                fieldId="date-time-end"
                                defaultHourValue={17}
                                defaultMinuteValue={0}
                                hoursToReserveAtStart={
                                    minBookingDurationInHours +
                                    minBookingDurationInHours
                                }
                                disableTyping
                                onChange={(newValue) => {
                                    if (!endDateTime) {
                                        return;
                                    }

                                    const newValueIsNull = !hasValue(newValue);

                                    if (
                                        newValueIsNull !==
                                        showInvalidTimeWarningRef.current
                                            .invalidEnd
                                    ) {
                                        setShowInvalidTimeWarning({
                                            ...showInvalidTimeWarningRef.current,
                                            invalidEnd: newValueIsNull,
                                        });
                                    }

                                    if (newValueIsNull) {
                                        return;
                                    }

                                    const newEnd = new Date(
                                        endDateTime.getTime()
                                    );
                                    newEnd.setHours(newValue[0]);
                                    newEnd.setMinutes(newValue[1]);
                                    newEnd.setSeconds(0);
                                    newEnd.setMilliseconds(0);

                                    if (
                                        newEnd.getTime() ===
                                        endDateTime.getTime()
                                    ) {
                                        return;
                                    }

                                    if (
                                        !startDateTime ||
                                        secondsToHours(
                                            millisecondsToSeconds(
                                                newEnd.getTime() -
                                                    startDateTime.getTime()
                                            )
                                        ) < minBookingDurationInHours
                                    ) {
                                        const newStart = new Date(
                                            newEnd.getTime()
                                        );
                                        newStart.setHours(
                                            newEnd.getHours() -
                                                minBookingDurationInHours
                                        );

                                        void setStartDateTime(newStart);
                                        startDateTimeInputFieldRef.current?.setValuesTo(
                                            [
                                                newStart.getHours(),
                                                newStart.getMinutes(),
                                            ]
                                        );
                                    }
                                    void setEndDateTime(newEnd);
                                }}
                            />
                        </div>
                    </div>
                    {(showInvalidTimeWarningState.invalidStart ||
                        showInvalidTimeWarningState.invalidEnd) && (
                        <div>
                            Meeting time is not valid. Please, check that you
                            entered start and end times correctly.
                        </div>
                    )}
                    {!(
                        showInvalidTimeWarningState.invalidStart ||
                        showInvalidTimeWarningState.invalidEnd
                    ) &&
                        startDateTime &&
                        endDateTime && (
                            <div
                                data-cy="meeting-duration"
                                className={meetingDurationStyle}
                            >
                                Meeting duration is{" "}
                                {secondsToHours(
                                    millisecondsToSeconds(
                                        endDateTime.getTime() -
                                            startDateTime.getTime()
                                    )
                                ).toFixed(1)}
                                h.
                            </div>
                        )}
                </div>
            </animated.div>
        </div>
    );
};

export default DateTimeFilterPopper;

import { mapQueryStateSortKey } from "@/app/(main)/discover/[[...params]]/(components)/(map)/mapQueryStateKeys";
import { NoValue } from "@/common/domain/entities/typing/NoValue";
import {
    sortOptions,
    sortParser,
} from "@/features/filtering/application/sortingConstants";
import { TSimplifiedHostLocationWithWorkspaceGroupsSchema } from "@/features/host-locations/domain/entities/schemas/SimplifiedHostLocationWithWorkspaceGroupsSchema";

import { useQueryState } from "nuqs";

interface UseSortedHostLocationsProps {
    locations: TSimplifiedHostLocationWithWorkspaceGroupsSchema[] | NoValue;
    filters: {
        hours: number;
    };
}

export const useSortedHostLocations = ({
    locations,
    filters,
}: UseSortedHostLocationsProps) => {
    const [sortBy] = useQueryState(mapQueryStateSortKey, sortParser);

    if (!locations) {
        return locations;
    }

    if (!sortBy) {
        return locations;
    }

    const sortOption = sortOptions.find((option) => option.type === sortBy);

    if (!sortOption) {
        return locations;
    }

    return locations.toSorted(sortOption.sortFn(filters));
};
